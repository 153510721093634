<template>
  <div class="container">
    <div class="forbidden">
      <h1 class="forbidden__title">
        403
      </h1>
      <div class="forbidden__text">
        Oops, You don't have permission to access this page.
      </div>
      <button
        class="forbidden__back"
        @click="$router.push('/')"
      >
        Back to home
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageForbidden',

  mounted () {
    this.$store.commit('users/setUserIsForbidden', false)
  }
}
</script>

<style lang="sass" scoped>
.forbidden
  padding: 4rem 2rem
  .forbidden__title
    font-size: 150px
    color: #008B62
.forbidden__text
  margin: 2rem 0
  font-size: 20px
  font-weight: 600
  color: #444
.forbidden__back
  display: inline-block
  border: 2px solid #222
  color: #222
  text-transform: uppercase
  font-weight: 600
  padding: 0.75rem 1rem 0.6rem
  transition: all 0.2s linear
  box-shadow: 0 3px 8px rgba(0,0,0, 0.3)
  cursor: pointer
  &:hover
    background: #222
    color: #ddd
</style>
